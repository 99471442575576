import React, { useRef, useEffect } from 'react';
import { Flex, Link } from 'rebass/styled-components';
import theme from './../../../gatsby-plugin-theme-ui';
import { TimelineMax } from 'gsap';

// COMPONENTS
import Heading from './../../atoms/heading';
import Text from './../../atoms/text';
import Button from './../../atoms/button';

export default function Modal(props) {

    const { status, onClick, title, text, button, onReadMore, accept, messageInfo } = props;
    let overlay_container_ref = useRef();
    let info_container_ref = useRef();
    let tl = useRef();

    const overlay_container_props = {
        sx: {
            position: 'fixed',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            background: `rgba(${theme.colors.lightgrey_rgba}, 0.65)`,
            zIndex: 1000,
            visibility: 'hidden',
            opacity: 0,
        }
    }

    const info_container_props = {
        sx: {
            flexFlow: 'column nowrap',
            position: 'absolute',
            top: '50%',
            left: 0,
            right: 0,
            margin: '0 auto',
            transform: 'translateY(-50%)',
            background: '#FFF',
            width: '90%',
            maxWidth: 600,
            minHeight: 280,
            maxHeight: messageInfo ? '90%' : 'auto',
            overflowY: messageInfo ? 'auto' : null,
            alignItems: 'center',
            padding: '2.5rem 0.5rem',
            visibility: 'hidden',
            opacity: 0,
            ':after': {
                content: messageInfo ? null : '""',
                position: 'absolute',
                top: 0,
                left: 0,
                width: 25,
                height: 25,
                borderTop: `2px solid ${theme.colors.red}`,
                borderLeft: `2px solid ${theme.colors.red}`,
            },
            ':before': {
                content: messageInfo ? null : '""',
                position: 'absolute',
                bottom: 0,
                right: 0,
                width: 25,
                height: 25,
                borderBottom: `2px solid ${theme.colors.red}`,
                borderRight: `2px solid ${theme.colors.red}`,
            }
        }
    }

    useEffect(() => {
        tl.current = new TimelineMax({ paused: true });

        tl.current.to(overlay_container_ref.current, 0.6, { autoAlpha: 1 })
          .to(info_container_ref.current, 0.3, { autoAlpha: 1 }, '-=0.2')
    }, [])


    useEffect(() => {
        if (status) {
            tl.current.play();
        } else {
            tl.current.reverse();
        }
    }, [status])

    return (
        <Flex ref={overlay_container_ref} {...overlay_container_props} onClick={accept ? null : onClick}>
            <Flex ref={info_container_ref} {...info_container_props}>
                <Heading as='h2' margin='0 0 2.5rem 0'>{title}</Heading>
                <Text textAlign='center' width='90%'>
                    {text} 
                {
                    button ? <Button onClick={onReadMore} margin='1rem 0 0 0'>Czytaj więcej...</Button> : !messageInfo ?
                    <Link
                    href='mailto: ardor@vp.pl'
                    sx={{
                        marginTop: '1rem',
                        textDecoration: 'none',
                        color: theme.colors.black,
                        display: 'block'
                    }}>
                        <strong> ardor@vp.pl</strong></Link> : null
                }</Text>
                <Button margin='1.5rem 0 0.5rem 0' onClick={onClick}>{accept ? '...Akceptuj...' : '...Wróć...'}</Button>
            </Flex>
        </Flex>
    );
}
